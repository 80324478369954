import {RouteObject} from 'react-router';

const lazyRoute = async (
  cmp: keyof typeof import('@app/dashboard/routes/dashboard-routes.lazy'),
) => {
  const exports = await import('@app/dashboard/routes/dashboard-routes.lazy');
  return {
    Component: exports[cmp],
  };
};

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    lazy: () => lazyRoute('DashboardPage'),
  },
  {
    path: 'dashboard/domains',
    lazy: () => lazyRoute('CustomDomainsPage'),
  },
  {
    path: 'dashboard/templates',
    lazy: () => lazyRoute('NewProjectPage'),
  },
  {
    path: 'dashboard/templates/:name',
    lazy: () => lazyRoute('TemplatePreviewPage'),
  },
];
