import React from 'react';
import {authGuard} from '@common/auth/guards/auth-route';
import {authRoutes} from '@common/auth/auth-routes';
import {notificationRoutes} from '@common/notifications/notification-routes';
import {createBrowserRouter, Outlet} from 'react-router';
import {LandingPage} from '@app/landing/landing-page';
import {GuestRoute} from '@common/auth/guards/guest-route';
import {RootErrorElement, RootRoute} from '@common/core/common-provider';
import {getBootstrapData} from '@ui/bootstrap-data/bootstrap-data-store';
import {adminRoutes} from '@common/admin/routes/admin-routes';
import {DynamicHomepage} from '@common/ui/other/dynamic-homepage';
import {dashboardRoutes} from '@app/dashboard/routes/dashboard-routes';
import {checkoutRoutes} from '@common/billing/checkout/routes/checkout-routes';
import {billingPageRoutes} from '@common/billing/billing-page/routes/billing-page-routes';
import {commonRoutes} from '@common/core/common-routes';
import {ActiveWorkspaceProvider} from '@common/workspace/active-workspace-id-context';
import {FullPageLoader} from '@ui/progress/full-page-loader';

export const appRouter = createBrowserRouter(
  [
    {
      id: 'root',
      element: <RootRoute />,
      errorElement: <RootErrorElement />,
      hydrateFallbackElement: <FullPageLoader screen />,
      children: [
        {
          path: '/',
          element: (
            <DynamicHomepage
              homepageResolver={() => {
                return (
                  <GuestRoute>
                    <LandingPage />
                  </GuestRoute>
                );
              }}
            />
          ),
        },
        {
          loader: () => authGuard({permission: 'projects.create'}),
          element: (
            <ActiveWorkspaceProvider>
              <Outlet />
            </ActiveWorkspaceProvider>
          ),
          children: [
            ...dashboardRoutes,
            {
              path: 'editor/:projectId',
              lazy: () => import('@app/editor/editor-page'),
            },
          ],
        },
        ...authRoutes,
        ...notificationRoutes,
        ...checkoutRoutes,
        ...billingPageRoutes,
        ...commonRoutes,
        ...adminRoutes,
        {
          path: 'api-docs',
          loader: () =>
            authGuard({permission: 'api.access', requireLogin: false}),
          lazy: () => import('@common/swagger/swagger-api-docs-page'),
        },
      ],
    },
  ],
  {
    basename: getBootstrapData().settings.html_base_uri,
  },
);
